import {
    BigUIntValue,
} from '@multiversx/sdk-core/out';
// import { EGLDPayment } from "api/sc/calls";
import BigNumber from 'bignumber.js';
import { ScCallwithEGLDTransfer, ScCallwithESDTNFTTransfer, ScCallwithESDTTransfer, ScCallwithMultiESDTNFTTransfer, ScCallwithNoTransfer } from '../../../src/api/sc/calls';

export const mintNfts = async (connectedUser: string, token: string, bigAmount: number, nrOfNfts: number, gasLimit: number) => {

    if (token === 'EGLD') {
        const res = await ScCallwithEGLDTransfer({
        workspace: 'minterWsp',
        sender: connectedUser,
        funcName: 'mintNfts',
        bigAmount: bigAmount,
        args: [new BigUIntValue(new BigNumber(nrOfNfts))],
        gasLimit: gasLimit
        });

        return res;
    } else {
        const res = await ScCallwithESDTTransfer({
            workspace: 'minterWsp',
            sender: connectedUser,
            funcName: 'mintNfts',
            token_identifier: token,
            bigAmount: bigAmount,
            args: [new BigUIntValue(new BigNumber(nrOfNfts))],
            gasLimit: gasLimit
        });

        return res;
    }
};