import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Row } from 'react-bootstrap';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';



import {
  AuthRedirectWrapper,
  ExtensionLoginButton,
  LedgerLoginButton,
  OperaWalletLoginButton,
  WalletConnectLoginButton,
  WebWalletLoginButton
} from 'components';
import { routeNames } from 'routes';

const UnlockPage = () => {
  const commonProps = {
    callbackRoute: routeNames.dashboard,
    nativeAuth: true // optional
  };






  return (
    <div className="home d-flex flex-fill align-items-center" style={{backgroundColor:'black'}}>

      <div className="col-sm d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh' }}>
  <div className="m-auto" data-testid="unlockPage">
    <h4 className="mb-4" style={{color:'white',textAlign:'center',fontSize:'35px',fontWeight:'700'}}><strong>Wanna Mint a Portal or two ?</strong></h4>
    <p className="mb-4" style={{color:'white',textAlign:'center',fontWeight:'700'}}>Pick your login method</p>


    <Row className="mb-2 text-center">
    <Col>
  <div style={{ width: '200px' }} className="mx-auto">
    <img src="Multiversx.png" width="50px" alt="Multiversx Logo" />
    <ExtensionLoginButton
      loginButtonText="DeFi Wallet"
      {...commonProps}
      className="btn btn-dark"
    />
  </div>
</Col>

    </Row>


    <Row className="mb-2" style={{textAlign:'center'}}>
      <Col >
        <OperaWalletLoginButton
          loginButtonText="Opera Crypto Wallet - Beta"
          {...commonProps}
          className="btn btn-dark"
        />
      </Col>
    </Row>
    <Row className="mb-2" style={{textAlign:'center'}}>
      <Col> <div style={{ width: '200px' }} className="mx-auto"><img src='Multiversx.png' width={'50px'}></img>
      <WebWalletLoginButton
          loginButtonText="Web Wallet"
          data-testid="webWalletLoginBtn"
          {...commonProps}
          className="btn btn-dark"
        /></div>
      </Col>
    </Row>
    <Row className="mb-2" style={{textAlign:'center'}}>
      <Col > <div style={{ width: '200px' }} className="mx-auto"><img src='Multiversx.png' width={'50px'}></img>
      <LedgerLoginButton
          loginButtonText="Ledger"
          className="btn btn-dark"
          {...commonProps}
        /></div>
      </Col>
    </Row>
    <Row className="mb-2" style={{textAlign:'center'}}>
      <Col ><img src='Multiversx.png' width={'50px'}></img>
        <WalletConnectLoginButton
          loginButtonText="Portal App"
          {...commonProps}
          className="btn btn-dark"
        />
      </Col>
    </Row>
  </div>
</div>


    </div>
  );
};

export const Unlock = () => (
  <AuthRedirectWrapper>
    <UnlockPage />
  </AuthRedirectWrapper>
);
