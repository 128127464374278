import { Address, Transaction } from '@multiversx/sdk-core/out';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import { ApiNetworkProvider, ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import minterAbi from '../../srbnftminter.abi.json';
import { chainID, contractAddresses, network } from '../net.config';
import { TransactionWatcher } from '@multiversx/sdk-core';

//abis import

/* Queries */
export const provider = new ProxyNetworkProvider(network.apiAddress, {
    timeout: 30000,
});

export const abiPath = '/api';

const apiNetworkProvider = new ApiNetworkProvider(network.apiAddress);

/* Messages */
const defaultProcessingMessage = 'Processing transaction';
const defaultPerrorMessage = 'An error has occured';
const defaultSuccessMessage = 'Transaction successful';
const defaulttransactionDuration = 1000 * 30;

export const EGLD_VAL = 1000000000000000000;

/* Calls */
export const runTransactions = async ({
    transactions,
    processingMessage = null,
    errorMessage = null,
    successMessage = null,
    transactionDuration = null,
}: {
    transactions: any;
    processingMessage?: string | null;
    errorMessage?: string | null;
    successMessage?: string | null;
    transactionDuration?: number | null;
}) => {
  
    const res = await sendTransactions({
      transactions: transactions,
      transactionsDisplayInfo: {
        processingMessage: processingMessage || defaultProcessingMessage,
        errorMessage: errorMessage || defaultPerrorMessage,
        successMessage: successMessage || defaultSuccessMessage,
        transactionDuration: transactionDuration || 60000,
      },
    });

    return res;
};

export type WspTypes = 
            | 'minterWsp'

export const getInterface = (workspace: WspTypes) => {

    let address = Address.Zero();
    // let address = contractAddress.claim;
    let abiUrl: any = null;
    let implementsInterfaces = '';
    let simpleAddress = '';

    switch (workspace) {
        case 'minterWsp':
            simpleAddress = contractAddresses.minter;
            address = new Address(simpleAddress);
            abiUrl = minterAbi;
            implementsInterfaces = 'MinterWsp';
            break;

        default:
            break;
    }

    return { address, abiUrl, implementsInterfaces, simpleAddress };
};