import {
    Address,
    AddressValue,
    BooleanValue,
    ITransactionOnNetwork,
    TransactionHash,
    TransactionWatcher
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { scQuery } from '../../api/sc/queries';
import { IScCostInfo, IScGlobalInfo, IScPayment, IScPhaseInfo } from '../types/sc.interface';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';
import { network } from 'api/net.config';

export const fetchGlobalInfo = async (): Promise<IScGlobalInfo> => {

    const scRes = await scQuery('minterWsp', 'getGlobalInfo', []);

    const data = scRes?.values;

    const finalData: IScGlobalInfo = {
        collection_identifier: data?.[0].valueOf().toString(),
        royalties: data?.[1].valueOf().toNumber(),
        user_max_mints: data?.[2].valueOf()[0].toNumber(),
        total_minted: data?.[2].valueOf()[1].toNumber(),
        left_to_mint: data?.[2].valueOf()[2].toNumber(),
    };

    return finalData;
};


export const fetchCostsInfo = async (address: string): Promise<IScCostInfo[]> => {

    const scRes = await scQuery('minterWsp', 'getCostsInfo', [new AddressValue(new Address(address))]);

    const data = scRes?.firstValue?.valueOf();

    const finalData: IScCostInfo[] = data.map((item: any) => {
        const cost: IScCostInfo = {
            cost_token: item?.[0].valueOf().toString(),
            cost_price: item?.[1].valueOf().toString(),
            max_mints_per_token: item?.[2].toNumber(),
            minted_per_token: item?.[3].toNumber(),
        };
        return cost;
    });

    return finalData;
};

export const fetchPhaseInfo = async (address: string): Promise<IScPhaseInfo> => {

    const scRes = await scQuery('minterWsp', 'getPhaseInfo', [new AddressValue(new Address(address))]);
    
    const data = scRes?.values;

    const info : number[] = data?.[3].valueOf().map((item: any) => {
        return item.toNumber();
    });

    const latest_minted_nonces : number[] = data?.[4].valueOf().map((item: any) => {
        return item.toNumber();
    });
    const last_mint_timestamp = latest_minted_nonces ? latest_minted_nonces.pop() : 0;

    const whitelistedAddresses: string[] = data?.[5].valueOf().map((item: any) => {
        return item.valueOf().toString();
    });

    const finalData: IScPhaseInfo = {
        phase_id: data?.[0].valueOf().toNumber(),
        is_phase_live: data?.[1].valueOf(),
        phase_label: data?.[2].valueOf().toString(),
        user_max_mints_in_phase: info?.[0],
        user_minted_in_phase: info?.[1],
        user_total_minted: info?.[2],
        total_minted_in_phase: info?.[3],
        latest_mint_timestamp: last_mint_timestamp,
        latest_mint_nonces: latest_minted_nonces,
        whitelisted_addresses: whitelistedAddresses,
    };

    return finalData;
};