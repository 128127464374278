import { SmartContract, Interaction, Account, TransactionWatcher, TransactionHash} from '@multiversx/sdk-core';
import {
  Address,
  ContractFunction,
  TokenTransfer
} from '@multiversx/sdk-core/out';import BigNumber from 'bignumber.js';
import { chainID, network } from '../../net.config';
import {
  EGLD_VAL,
  WspTypes,
  getInterface,
  runTransactions
} from '../sc';


export const ScCallwithNoTransfer = async ({
  workspace,
  sender,
  funcName,
  args = [],
  gasLimit = 50000000,
} : {
  workspace: WspTypes;
  sender: string;
  funcName: string;
  args?: any[];
  gasLimit?: number;
  amount?: number;
  bigAmount?: number;
}) => {
  let { simpleAddress } = getInterface(workspace);

  if (simpleAddress === '') {
    simpleAddress = workspace;
  }

  const senderAddress = new Address(sender);

  const contract = new SmartContract({ address: new Address(simpleAddress)});
  const interaction = new Interaction(contract, new ContractFunction(funcName), args);

  const tx = interaction
    .withSender(senderAddress)
    .useThenIncrementNonceOf(new Account(senderAddress))
    .withValue(0)
    .withGasLimit(gasLimit)
    .withChainID(chainID)
    .buildTransaction();

  const transactionInput = { transactions: [tx] };

  return await runTransactions(transactionInput);
};


export const ScCallwithEGLDTransfer = async ({
  workspace,
  funcName,
  args = [],
  gasLimit = 90000000,
  amount = 0,
  bigAmount = null,
  sender = null,
} : {
  workspace: WspTypes;
  funcName: string;
  args?: any[];
  gasLimit?: number;
  amount?: number;
  bigAmount?: number;
  sender?: any;
}) => {
  let { simpleAddress } = getInterface(workspace);

  if (simpleAddress === '') {
    simpleAddress = workspace;
  }

  const senderAddress = new Address(sender);

  const contract = new SmartContract({ address: new Address(simpleAddress)});
  const interaction = new Interaction(contract, new ContractFunction(funcName), args);

  const tx = interaction
    .withSender(senderAddress)
    .useThenIncrementNonceOf(new Account(senderAddress)) // den xerw an xreiazetai auto
    .withValue(bigAmount ?? amount * EGLD_VAL)
    .withGasLimit(gasLimit)
    .withChainID(chainID)
    .buildTransaction();

  const transactionInput = { transactions: [tx] };

  return await runTransactions(transactionInput);
};

export const ScCallwithESDTTransfer = async ({
  workspace,
  funcName,
  token_identifier,
  bigAmount,
  args = [],
  gasLimit = 90000000,
  sender = null,
} : {
  workspace: WspTypes;
  funcName: string;
  token_identifier: any;
  args?: any[];
  gasLimit?: number;
  bigAmount: number;
  sender?: any;
}) => {
  let { simpleAddress } = getInterface(workspace);

  if (simpleAddress === '') {
    simpleAddress = workspace;
  }

  const senderAddress = new Address(sender);

  const contract = new SmartContract({ address: new Address(simpleAddress)});
  const interaction = new Interaction(contract, new ContractFunction(funcName), args);

  const tx = interaction
    .withSender(senderAddress)
    .withSingleESDTTransfer(TokenTransfer.fungibleFromBigInteger(token_identifier, new BigNumber(bigAmount)))
    .useThenIncrementNonceOf(new Account(senderAddress))
    .withValue(0)
    .withGasLimit(gasLimit)
    .withChainID(chainID)
    .buildTransaction();

  const transactionInput = { transactions: [tx] };

  return await runTransactions(transactionInput);
};

export const ScCallwithESDTNFTTransfer = async ({
  workspace,
  sender,
  funcName,
  token_identifier,
  token_nonce,
  args = [],
  gasLimit = 90000000,
} : {
  workspace: WspTypes;
  sender: string;
  funcName: string;
  token_identifier: any;
  token_nonce: any;
  args?: any[];
  gasLimit?: number;
}) => {
  let { simpleAddress } = getInterface(workspace);

  if (simpleAddress === '') {
    simpleAddress = workspace;
  }

  const senderAddress = new Address(sender);

  const contract = new SmartContract({ address: new Address(simpleAddress)});
  const interaction = new Interaction(contract, new ContractFunction(funcName), args);

  const tx = interaction
    .withSender(senderAddress)
    .withSingleESDTNFTTransfer(TokenTransfer.nonFungible(token_identifier, token_nonce))
    .withValue(0)
    .withGasLimit(gasLimit)
    .withChainID(chainID)
    .buildTransaction();

  const transactionInput = { transactions: [tx] };

  return await runTransactions(transactionInput);
};

export const ScCallwithMultiESDTNFTTransfer = async ({
  workspace,
  sender,
  funcName,
  token_identifiers,
  token_nonces,
  args = [],
  gasLimit = 90000000,
} : {
  workspace: WspTypes;
  sender: string;
  funcName: string;
  token_identifiers: any[];
  token_nonces: any[];
  args?: any[];
  gasLimit?: number;
}) => {
    let { simpleAddress } = getInterface(workspace);

    if (simpleAddress === '') {
      simpleAddress = workspace;
    }

    const senderAddress = new Address(sender);

    const contract = new SmartContract({ address: new Address(simpleAddress)});
    const interaction = new Interaction(contract, new ContractFunction(funcName), args);

    const tokenTransfers = [];

    for (let i = 0; i < token_identifiers.length; i++) {
      const token_identifier = token_identifiers[i];
      const token_nonce = token_nonces[i];

      // Create the TokenTransfer object and add it to the array
      const tokenTransfer = TokenTransfer.nonFungible(token_identifier, token_nonce);

      tokenTransfers.push(tokenTransfer);
    }

    // The tokenTransfers array will now contain objects in the format: TokenTransfer.nonFungible(token_identifier, token_nonce)

    const tx = interaction
      .withSender(senderAddress)
      .withMultiESDTNFTTransfer(tokenTransfers)
      .withValue(0)
      .withGasLimit(gasLimit)
      .withChainID(chainID)
      .buildTransaction();

    const transactionInput = { transactions: [tx] };

    return await runTransactions(transactionInput);
};