import { Address, AddressValue, ITransactionOnNetwork, TransactionHash, TransactionWatcher } from '@multiversx/sdk-core/out';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers/out';
import useSwr from 'swr';
import { network } from 'api/net.config';
import { IScCostInfo, IScGlobalInfo, IScPhaseInfo } from '../types/sc.interface';
import { fetchCostsInfo, fetchGlobalInfo, fetchPhaseInfo } from './queries';

export const useGetCostsInfo = (address: string) => {
    const {
        data: costsInfo,
        isLoading,
    } = useSwr<IScCostInfo[]>(
        address && address != '' ? `minterWsp:getCostsInfo:${address}` : null,
        async () => {
            return await fetchCostsInfo(address);
        },
        {
            fallbackData: [],
            refreshInterval: 30000,
        }
    );

    return {
        costsInfo: costsInfo,
        isLoadingCostsInfo: isLoading,
    };
};

export const useGetGlobalInfo = () => {
    const {
        data: globalInfo,
        isLoading,
    } = useSwr<IScGlobalInfo>(
        'minterWsp:getGlobalInfo',
        async () => {
            return await fetchGlobalInfo();
        },
        {
            fallbackData: {
                collection_identifier: '',
                // collection_name: '',
                royalties: 0,
                user_max_mints: 0,
                total_minted: 0,
                left_to_mint: 0,
            },
            refreshInterval: 30000,
        }
    );

    return {
        globalInfo: globalInfo,
        isLoadingGlobalInfo: isLoading,
    };
};

export const useGetPhaseInfo = (address: string) => {
    const {
        data: phaseInfo,
        isLoading,
    } = useSwr<IScPhaseInfo>(
        address && address != '' ? `minterWsp:getPhaseInfo:${address}` : null,
        async () => {
            return await fetchPhaseInfo(address);
        },
        {
            fallbackData: {
                phase_id: null,
                is_phase_live: false,
                phase_label: '',
                user_max_mints_in_phase: 0,
                user_minted_in_phase: 0,
                user_total_minted: 0,
                total_minted_in_phase: 0,
                latest_mint_timestamp: 0,
                latest_mint_nonces: [],
                whitelisted_addresses: [],
            },
            refreshInterval: 30000,
        }
    );

    return {
        phaseInfo: phaseInfo,
        isLoadingPhaseInfo: isLoading,
    };
};

export const useGetWatcherResults = async (txHash: string): Promise<ITransactionOnNetwork | null> => {
    try {
        const apiNetworkProvider = new ApiNetworkProvider(network.apiAddress);

        const watcher = new TransactionWatcher(apiNetworkProvider);
        const transactionOnNetwork = await watcher.awaitCompleted({ getHash: () => new TransactionHash(txHash) });

        return transactionOnNetwork;
    } catch (error) {
        console.error('An error occurred:', error);
        return null; // Return null in case of error
    }
};
