import * as React from 'react';
import { Link } from 'react-router-dom';
import { AuthRedirectWrapper } from 'components';
import { routeNames } from 'routes';

const HomePage = () => {
  return (
    <>
    <div className='row w-100 h-100 no-gutters'>
      <div className="col-sm" style={{ height: '100vh' }}>
        <div className="h-50 d-flex flex-column justify-content-center" style={{ fontSize: '45px', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(Multiversx.png)',backgroundSize:'35%',backgroundPosition:'right top',backgroundRepeat:'no-repeat'  }}>
          <span style={{width:'70%',fontSize:'35px',padding:'25px'}}>HYPEY Portals. The gateway to the HYPED AF collection (coming soon)</span>
          <span style={{fontSize:'45px',marginLeft: '30px',marginBottom:'20px'}}><strong>2222 Portals </strong></span>
        </div>
        <div className="h-50 d-flex flex-column justify-content-center" style={{ fontSize: '45px', backgroundColor: 'black',boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white' }}>
          <div><img src="hypey.png" width={'280px'} height={'auto'}></img>
          <Link style={{marginBottom:'60px'}}
          to={routeNames.unlock}
          className='btn btn-lg btn-dark mt-3 text-white'
          data-testid='loginBtn'>
          Mint NFT
        </Link></div>
        </div>
      </div>
      <div className="col-sm align-items-center justify-content-between" style={{ height: '100vh' }}>
        <div className="d-flex flex-column justify-content-center align-items-center " style={{ fontSize: '25px',height:'65%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(goldhypey.png)',backgroundSize:'55%',backgroundPosition:'center top',backgroundRepeat:'no-repeat'}}>
          <span className='mt-auto' style={{fontSize:'25px',marginTop:'25px',}}><strong>Mint a HYPEY PORTAL</strong></span>
          <span style={{width:'50%',fontSize:'14px',marginTop:'15px',marginBottom:'25px',textAlign:'center'}}>Portals are used to mint the HYPED AF collection</span>
          <span style={{width:'50%',fontSize:'14px',marginTop:'15px',marginBottom:'25px',textAlign:'center'}}>There will be 2222 Hyperdimensional shifted degens to get your hands on</span>
          <span style={{width:'50%',fontSize:'14px',marginTop:'15px',marginBottom:'25px',textAlign:'center'}}>To mint, you will need an HYPEY Portal & a HYPEY NFT,  both of which will be burned</span>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center" style={{ fontSize: '45px',height:'35%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white' ,
      }}>
        <span style={{fontSize:'35px',padding:'20px',textAlign:'center'}}><strong> Portals to transform your HYPEYs</strong> </span>
        <span style={{fontSize:'25px'}}>Are you as excited as we are? </span>
        </div>
      </div>
      <div className="col-sm" style={{ height: '100vh' }}>
        <div className="d-flex flex-column justify-content-center" style={{ fontSize: '45px',height:'65%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(hypeysleep.png)',backgroundSize:'55%',backgroundPosition:'right top',backgroundRepeat:'no-repeat' }}>
          <span className='mt-auto' style={{width:'65%',fontSize:'45px',padding:'25px'}}>Your Hyperdimensional gateway to the HYPEY verse</span>
        </div>
        <div className="d-flex flex-column justify-content-center" style={{ fontSize: '45px',height:'35%', backgroundColor: 'black', boxShadow:'0 0 0 0.2px #FFFFFF', color: 'white',
      backgroundImage:'url(lauhypey.png)',backgroundSize:'45%',backgroundPosition:'left left',backgroundRepeat:'no-repeat' }}>
          <span style={{textAlign:'right',marginRight:'20px'}}>Mint with</span>
          <div style={{textAlign:'end'}}>
          <img style={{marginRight:'20px'}} src="Multiversx.png" width={'60px'} height={'auto'}></img>
          </div>

        </div>
      </div>
    </div>
  </>

  );
};

export const Home = () => (
  <AuthRedirectWrapper>
    <HomePage />
  </AuthRedirectWrapper>
);
